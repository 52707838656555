<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="refCredit" backlink="/profile" />
      <div class="content">
        <div class="content__container">
          <Question
            service="refCredit"
            name="add_income"
            question="question_1"
            successUrl="/refinance-credit/about-incomes"
            negativeUrl="/refinance-credit/expense"
            :droppedSteps="['/refinance-credit/about-incomes']"
            :negativeMissedSteps="['/refinance-credit/about-incomes']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'
import { commonMixin } from '@/mixins/commonMixin.js'
export default {
  name: 'Refinance_credit_income',
  components: { Backlink, Question },
  mixins: [commonMixin],
  created () {
    // Установка количества шагов для услуги
    this.setAllSteps('refCredit', 25)
  }
}
</script>
